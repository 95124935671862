<template>
  <section>
    <div class="flex flex-no-wrap">
      <Nav />

      <section class="enterprise-setup h-100 p-3 pb-5">
        <div class="container mb-2">
          <div class="d-flex align-items-center justify-content-center h-100 mt-3 mb-3 pt-2 pb-2">
            <div class="d-flex align-items-center me-auto">
              <div class="flex-shrink-0">
                <img
                  src="@/assets/images/logo.png"
                  class="logo"
                  alt="Relatus"
                >
              </div>
              <div class="flex-grow-1 ms-3">
                <h2><span class="font-normal">Your</span> Relatus Profile</h2>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <r-button
                variant="primary"
                round
                outline
                class="ml-auto"
              >
                Save Changes
              </r-button>
            </div>
          </div>
        </div>

        <div class="container container-card h-100 p-2">
          <div class="row">
            <div class="col-12 text-start">
              <div class="d-flex align-items-center border-light-gray ps-4 pe-4" style="border: 1px solid rgba(3, 29, 90, 0.2);box-sizing: border-box;border-radius: 12px;margin: 20px;">
                <div class="flex-shrink-0">
                  <img
                    src="@/assets/images/icons/undraw_interests.svg"
                    alt="Enterprise Setup"
                    style="max-height: 70px;"
                  >
                </div>
                <div class="flex-grow-1 ms-3">
                  <h5 class="text align-self-center me-auto fw-light" style="color: #323232;">
                    Select your Interests(s)
                  </h5>
                  <p class="text align-self-center text-start" style="color: #999799;">
                    You can confirm or edit the information we’ve collected about you here. This information belongs to you and you alone.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container h-100 mt-5 p-0">
          <div class="row align-items-center">
            <div class="col-4">
              <div class="container-card">
                <router-link
                  :to="{name: 'connect.network'}"
                >
                  <img
                    src="@/assets/images/icons/undraw_workout_gcgu_1.svg"
                    alt="Wellness"
                  >
                  <h5>Wellness</h5>
                  <div class="mt-3">
                    <span class="tag active">Weights</span>
                    <span class="tag active">Fitness</span>
                    <span class="tag">Medicine</span>
                  </div>
                </router-link>
              </div>
            </div>
            <div class="col-4">
              <div class="container-card">
                <router-link
                  :to="{name: 'enhance.profile'}"
                >
                  <img
                    src="@/assets/images/icons/undraw_art_museum_8or4.svg"
                    alt="Arts"
                  >
                  <h5>Arts</h5>
                  <div class="mt-3">
                    <span class="tag active">Design</span>
                    <span class="tag active">Fashion</span>
                    <span class="tag">Beauty</span>
                  </div>
                </router-link>
              </div>
            </div>
            <div class="col-4">
              <div class="container-card">
                <img
                  src="@/assets/images/icons/undraw_road_to_knowledge_m8s0.svg"
                  alt="Knowledge"
                >
                <h5>Knowledge</h5>
                <div class="mt-3">
                  <span class="tag active">History</span>
                  <span class="tag active">Biology</span>
                  <span class="tag">Philosophy</span>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="container-card">
                <img
                  src="@/assets/images/icons/undraw_game_day_ucx9.svg"
                  alt="Sports"
                >
                <h5>Sports</h5>
                <div class="mt-3">
                  <span class="tag active">Tennis</span>
                  <span class="tag active">Soccer</span>
                  <span class="tag">Basketball</span>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="container-card">
                <img
                  src="@/assets/images/icons/undraw_Around_the_world_re_n353.svg"
                  alt="Languages"
                >
                <h5>Languages</h5>
                <div class="mt-3">
                  <span class="tag active">Mandarin</span>
                  <span class="tag active">Hindi</span>
                  <span class="tag">German</span>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="container-card">
                <img
                  src="@/assets/images/icons/undraw_town_r6pc.svg"
                  alt="Life"
                >
                <h5>Life</h5>
                <div class="mt-3">
                  <span class="tag active">Dating</span>
                  <span class="tag active">Pregnancy</span>
                  <span class="tag">Relationships</span>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="container-card">
                <img
                  src="@/assets/images/icons/undraw_career_progress_ivdb.svg"
                  alt="Hustle"
                >
                <h5>Hustle</h5>
                <div class="mt-3">
                  <span class="tag">Small Business</span>
                  <span class="tag">Entrepreneurship</span>
                  <span class="tag">YouTube</span>
                  <span class="tag active">Real State</span>
                  <span class="tag active">Instagram</span>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="container-card">
                <img
                  src="@/assets/images/icons/undraw_Having_fun_re_vj4h.svg"
                  alt="Hanging Out"
                >
                <h5>Hanging Out</h5>
                <div class="mt-3">
                  <span class="tag active">Design</span>
                  <span class="tag active">Fashion</span>
                  <span class="tag">Beauty</span>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="container-card">
                <img
                  src="@/assets/images/icons/undraw_mindfulness_scgo.svg"
                  alt="Faith"
                >
                <h5>Faith</h5>
                <div class="mt-3">
                  <span class="tag active">Christianity</span>
                  <span class="tag active">Hinduism</span>
                  <span class="tag">Atheism</span>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="container-card">
                <img
                  src="@/assets/images/icons/undraw_the_world_is_mine_nb0e.svg"
                  alt="World Affairs"
                >
                <h5>World Affairs</h5>
                <div class="mt-3">
                  <span class="tag active">Social Issues</span>
                  <span class="tag active">Geopolitics</span>
                  <span class="tag">Climate</span>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="container-card">
                <img
                  src="@/assets/images/icons/undraw_adventure_4hum.svg"
                  alt="Places"
                >
                <h5>Places</h5>
                <div class="mt-3">
                  <span class="tag">Shanghai</span>
                  <span class="tag">Australia</span>
                  <span class="tag">New Zealand</span>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="container-card">
                <img
                  src="@/assets/images/icons/undraw_tutorial_video_vtd1.svg"
                  alt="Tech"
                >
                <h5>Tech</h5>
                <div class="mt-3">
                  <span class="tag active">DTC</span>
                  <span class="tag active">Crypto</span>
                  <span class="tag">Marketing</span>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="container-card">
                <img
                  src="@/assets/images/icons/undraw_horror_movie_3988.svg"
                  alt="Entertainment"
                >
                <h5>Entertainment</h5><div class="mt-3">
                  <span class="tag active">Anime & Manga</span>
                  <span class="tag active">Trivia</span>
                  <span class="tag">Celebrities</span>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="container-card">
                <img
                  src="@/assets/images/icons/undraw_personal_information_re_vw8a.svg"
                  alt="Identify"
                >
                <h5>Identify</h5>
                <div class="mt-3">
                  <span class="tag active">East Asian</span>
                  <span class="tag active">BIPOC</span>
                  <span class="tag">Black</span>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 text-right">
              <router-link
                :to="{name: 'claim.profile'}"
                class="mr-3"
              >
                <r-button
                  variant="primary"
                  round
                  outline
                  class="ml-auto"
                >
                  Back to Top
                </r-button>

              </router-link>

              <router-link
                :to="{name: 'claim.profile'}"
              >
                <r-button
                  variant="primary"
                  round
                  outline
                  class="ml-auto"
                >
                  Save Changes
                </r-button>

              </router-link>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import Nav from '@/components/nav/nav.vue';
export default {
  name: 'Interests',
  components: {
    Nav
  },
}
</script>

<style scoped src="@/css/bootstrap.min.css"></style>

<style scoped>
.enterprise-setup {
  background-color: #EDEDED;
}
.enterprise-setup h2 {
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  color: #000000;
  letter-spacing: 1px;
  margin-bottom: 0;
}
.enterprise-setup h3 {
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  color: #142328;
  letter-spacing: 1px;
}
.enterprise-setup h5 {
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #000000;
  margin-top: 20px;
  max-height: 30px;
}
.enterprise-setup .container:first-child > .d-flex {
  border-bottom: 0.5px solid #332C54;
}
.logo {
  max-height: 33px!important;
}
.enterprise-setup img {
  height: 125px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.container-card {
  padding: 40px 40px;
  margin-bottom: 30px;
  background-color: #FFFFFF;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
button {
  background-color: #FFFFFF!important;
  border: 1px solid #83C03B;
  box-sizing: border-box;
  border-radius: 30px;
  display: inline-block;
}
button:hover {
  color: #83C03B!important;
  border: 1px solid #83C03B!important;
}
a {
  text-decoration: none;
}
.tag {
  display: inline-block;
  padding: 5px;
  background: rgba(3, 29, 90, 0.2);
  border-radius: 3px;
  margin: 0px 5px;
  color: #1E1E1E;
  font-size: 14px;
}
.tag.active {
  background: #83C03B;
  color: #F9F9F9;
}
</style>
